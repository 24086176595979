import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/App.css";
import FooterBar from "../components/Footer";
import CookieConsent from "react-cookie-consent";
import Navigation from "./Navigation";

export default ({ transparent, children }) => {
  const menuRef = useRef();
  const [menuOpen, toggleMenu] = useState(false);
  const handleToggleMenu = (e) => {
    toggleMenu(e);
  };
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <CookieConsent
        location="bottom"
        hideOnAccept={true}
        buttonText="I agree"
        declineButtonText="No thanks"
        style={{
          background: "#151515",
          color: "#FFFFFF",
        }}
        buttonStyle={{
          color: "#151515",
          background: "#FFFFFF",
          fontSize: "1rem",
        }}
        declineButtonStyle={{
          color: "#FFFFFF",
          background: "#F15A24",
          fontSize: "1rem",
        }}
        declineButtonClasses="decline-cookie-button"
        buttonClasses="accept-cookie-button"
        expires={150}
        enableDeclineButton
        
      >
        We use cookies to improve user experience and analyse website traffic.
        Therefore we may share information with third parties. Please click "I
        agree" to opt-in and remain on the website.
      </CookieConsent>
      <div ref={menuRef}>
        <Navigation menuOpen={menuOpen} handleToggleMenu={handleToggleMenu} />
      </div>
      <div>{children}</div>
      <FooterBar />
    </>
  );
};
