import React from "react";
import "../assets/css/Footer.css";
import { Link } from "gatsby";
// import logo from "../assets/img/SVG/new-logo2.svg";
import logo from "../assets/img/sass holdings-logos.jpeg";
function FooterBar() {
  return (
    <div className="footer">
      <div className="max-container">
        <div className="footer-contents">
          <div className="footer-item">
            <img src={logo} alt="Sicily-Pizzeria-Norwich-logo" />
          </div>
        </div>
        <div className="footer-contents">
          {/*  */}

          <div className="footer-item">
            <div className="footer-list-header">Business Links</div>
            <div className="footer-list-item">
              &copy; SASS Holdings - London
            </div>
            <div className="footer-list-link">
              <Link to="/privacy-policy/" exact="true">
                Privacy Policy
              </Link>
            </div>
            <div className="footer-list-link">
            </div>
          </div>
          {/*  */}
        </div>
        {/* <Maps /> */}
      </div>
    </div>
  );
}
export default FooterBar;
