import React, { useState } from "react";
import { faEnvelope, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "@reach/router";
import logo from "../assets/img/sass holdings-logos.jpeg";
import "../assets/css/Navigation.css";
import NavLink from "./NavLink";
import { Modal } from "react-bootstrap";
import BookingModal from "./BookingModal";
import { Link } from "gatsby";

const menuItems = [];

const subItemSelected = (location, subItems) => {
  let selected = false;
  if (subItems && subItems.find((item) => item.url.startsWith(location))) {
    selected = true;
  }
  return selected;
};
const Navigation = () => {
  const location = useLocation();
  const [menuOpen, toggleMenu] = useState(false);
  const [subItem, toggleSubItem] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="navbar">
      <div className="max-container">
        <div className="menu-container">
          <div className="menu-booking">
            <div onClick={handleShow} className="reservation-button">
              <span className="reservation-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <span className="reservation-text">Contact</span>
            </div>
          </div>
          <div className="menu-branding">
            <Link to="/" className='d-flex'>
              <img
                src={logo}
                className="d-inline-block w-100"
                alt="Sicily-Market-logo"
              />
            </Link>
          </div>

          <div className="menu-booking">
            <div className="reservation-button">
              <span className="reservation-icon">
                <a href='#about-us' className='font-black'>
                <FontAwesomeIcon className='font-black' href='#about-us' icon={faInfoCircle} />
                </a>
              </span>
              <a href='#about-us' className="reservation-text font-black">About us</a>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="text-center p-3 white-container">Contact us</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BookingModal />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Navigation;
