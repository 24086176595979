import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/BookingModal.css";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

const BookingModal = () => {
  const [contactSuccess, changeContactSuccess] = useState(false);
  const [contactError, changeContactError] = useState(false);
  const [contactLoading, changeContactLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    changeContactSuccess(false);
    changeContactError(false);
    changeContactLoading(true);

    emailjs
      .send(
        "amazon_ses",
        "template_NounNW6p",
        {
          ...data,
        },
        "user_uB4PopJwbqrSoyPz8LPXg"
      )
      .then(
        () => {
          changeContactSuccess(true);
          changeContactLoading(false);
        },
        () => {
          changeContactError(true);
          changeContactLoading(false);
        }
      );
  };
  const showForm = !contactLoading && !contactSuccess;

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {contactError && (
                <Alert variant="danger">
                  <Alert.Heading>Information</Alert.Heading>
                  <p>Sorry but your contact request failed, please contact us via email.</p>
                </Alert>
              )}
              {contactSuccess && (
                <Alert variant="success">
                  <Alert.Heading>Contact request has been sent</Alert.Heading>
                  <p>
                    Thank you for contacting us, we will get back to you as soon as possible.
                  </p>
                </Alert>
              )}
              {contactLoading && (
                <Alert variant="primary">
                  <Alert.Heading>Sending...</Alert.Heading>
                </Alert>
              )}
              {showForm && (
                <>
                  <Form.Group controlId="from_name">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="from_name"
                      ref={register({ required: true })}
                      isInvalid={errors.fullName}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="reply_to">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="reply_to"
                      ref={register({ required: true })}
                      isInvalid={errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="contact_number">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      name="contact_number"
                      ref={register({required: true})}
                      isInvalid={errors.phone_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="message_html">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type="textarea"
                      as='textarea'
                      name="message_html"
                      rows="5"
                      ref={register({required: true})}
                      isInvalid={errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary button-colour"
                    type="submit"
                    disabled={contactLoading}
                  >
                    Submit
                  </Button>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BookingModal;
